import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';

class App extends Component 
{
	constructor(props)
	{
		super(props)
		this.state = {
			qr : "",
			nombre : '',
		}
	}

	submit=(evt)=>
	{
		evt.preventDefault();
		var { qr } = this.state;
		this.RegistrarEntrada(qr);
		qr="";
		this.setState({ qr : qr });
	}

	submitPass=(evt)=>
	{
		evt.preventDefault();
	}

	RegistrarEntrada=(cod)=>
	{
		var { qr, nombre } = this.state;
		let temp = this;

		request.get(`/RegistrarEntrada/${qr}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
		 		nombre = response.data;
		 		temp.setState({ nombre : nombre });
		 	}
		})
		.catch(error => {} );
		this.setState({ nombre : nombre });
	}

	handleInputChange=(event)=>
	{
		const value = event.target.value;
		var { qr } = this.state;
		qr = value;
		this.setState({ qr : qr });
	}

	render()
	{
		let { nombre, qr } = this.state;

		return (
			<div className="body">		
				<div className="main">
					<Row className="pl-1 pr-1">
						<Col className="col-12">
							<Form onSubmit={this.submit}>
								<Row>
									<Col>
										<div className="pl-1 pr-1 input-group mb-1 loginForm">
											<span className="input-group-text" id="basic-addon1"> Registro de entrada &nbsp; <span className="oi ver" data-glyph="cog" aria-hidden="true"> </span> </span>
											<input type="password" autoFocus autoComplete="off" className="form-control" placeholder="" value={qr} aria-label="qr" name="qr" id="qr" aria-describedby="basic-addon1" onChange={this.handleInputChange} />
											<span className="input-group-text">CBTIS #41</span>
										</div>
									</Col>
								</Row>
							</Form>
				
							{/* CUADRO DE FOTO */}					
							<Row>
								<Col>
									<div className="pl-2 pr-2 mb-1 loginForm">
										<table>
											<tbody>
												<tr className="f ml-2 cen">
													<td className="datos cen">
														<span className="ne b cen midtext"> {nombre} </span>									
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
								
							{/* AULA */}
							
							<Row>
								<Col>
								{
									nombre === false ?
										<div className="pl-2 pr-2 loginForm maxh lista fro">
											
										</div>
									:
										<div className="pl-2 pr-2 loginForm maxh lista fve">		
										</div>
								}
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			
			</div>
		);
	}
}

export default App;